import React,{useState,useEffect} from "react";
import { Col, Progress, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import globe from "../../images/icons/global.png";
import twitter from "../../images/icons/twitterblack.png";
import telegram from "../../images/icons/blacktelegram.png";
import discord from "../../images/icons/blackmedium.png";
import NJW from "../../images/NJW.png";


import styles from "./poolCard.module.scss";
import {useWallet} from '@binance-chain/bsc-use-wallet'

import config, { API_URL } from "../../BlockchainProvider/config"
import SHOT_ABI from "../../BlockchainProvider/ABI/NEW_IDO_SHOT.json"
import WOLF_NEW_IDO_ABI from "../../BlockchainProvider/ABI/WOLF_NEW_IDO.json"

import STAKING_ABI from  "../../BlockchainProvider/ABI/NEWSTAKING.json"
import Web3 from "web3"
const PoolCardShot = ({
  id,
  _id,
  status,
  description,
  currencyRate,
  currencyRateType,
  cap,
  acess,
  participants,  
  percentage,
  completion,
  website,
  twitterLink,
  telegramLink,
  discordLink,
  closed,
  logo,
  ido
}) => {



  
  const [icoTarget, setIcoTarget] = useState()
  const [receivedFund, setReceivedFund] = useState()

  const [fundRaisingProgress, setFundRaisingProgress] = useState(0)
  const [maxInv, setmaxInv] = useState(0)

  const [_participants,setParticipant] = useState();

  
  const history = useHistory();
  const statusArray = ["","Open" ,"Closed"] ;


  const wallet = useWallet();

  useEffect(() => {
    // if(id==1){
    //   if (wallet.status === 'connected' ) {

    //     initContracts();
    //   }
    // }
    // else if(id==1){
    //   if (wallet.status === 'connected' ) {

    //     initContractsNew();
    //   }
    // }
    // else{
    //   setmaxInv(participants)
    //   setFundRaisingProgress(100)
    // }
    if(ido != "#"){
      initContracts();

    }

 
  }, [wallet]);
  
  
  
  // const initContractsNew = async () => {
  //   let _web3 = new Web3(window.ethereum);
  //   const _icoContract = new _web3.eth.Contract(SHOT_ABI, config.NEW_IDO_SHOT)
   

  //   let _icoTarget = await _icoContract.methods.icoTarget().call();
  //   setIcoTarget((_icoTarget/1e18).toFixed(0));


  //   let _receivedFund = await _icoContract.methods.receivedFund().call();
  //   setReceivedFund((_receivedFund/1e18).toFixed(0));

  //   let progressPercent = 0 ;
  //   if(_receivedFund > 0){
  //      progressPercent = (_receivedFund/_icoTarget)*100

  //     if(progressPercent<20){
  //       progressPercent = 20
  //     }
  
  //   }

  //   console.log("closed",progressPercent)
    
  //   let _maxinvest = await _icoContract.methods.maxInvestment().call();
  //   _maxinvest = parseFloat(_maxinvest/1e18).toFixed()
  //   console.log("_maxinvest",_maxinvest)

  //   setmaxInv(_maxinvest)

  //   if(closed){
  //     setFundRaisingProgress(100)

  //   }else{
  //     setFundRaisingProgress(progressPercent)

  //   }

 

  // }
  
  const initContracts = async () => {

   

    let _web3 = new Web3(window.ethereum);
    const _icoContract = new _web3.eth.Contract(SHOT_ABI, ido)
   

    let _icoTarget = await _icoContract.methods.icoTarget().call();
    setIcoTarget((_icoTarget/1e18).toFixed(0));


    let _receivedFund = await _icoContract.methods.receivedFund().call();
    setReceivedFund((_receivedFund/1e18).toFixed(0));
    let progressPercent = 0 ;
    if(_receivedFund > 0){
      let progressPercent = (_receivedFund/_icoTarget)*100
      if(progressPercent<20){
        progressPercent = 20
      }
  
    }

    console.log("closed",progressPercent)

    if(closed){
      setFundRaisingProgress(100)

    }else{
  

      setFundRaisingProgress(progressPercent);
  

    }


    initializeParticipantCount(_web3)

  }


  const renderCompletion = ()=>{
 
      return receivedFund+"/"+icoTarget;
     
  }


  
  const initializeParticipantCount = async(web3)=>{
    const participantsContracts = new web3.eth.Contract(STAKING_ABI, config.NEW_STAKING);
    let participantCounts = await participantsContracts.methods.countStakers().call();

    setParticipant(participantCounts)
  }






  function openPool(id, desc, web, twitter, tele, discord,rocketArray){
    // setTimeout(()=>{
      // history.push('/poolDetail');
      // alert(id);
      // if(id==1){
      //   sessionStorage.setItem('pool', JSON.stringify({id:id, desc:desc, web:web, twitter:twitter, tele:tele, 
      //     discord:discord, name:"", token:"NAUT", supply:"1,500,000,000", status:"Open in TBA"}))
      //  // history.push('/WalletConnect');
      // }
      // else if(id==2){
      //   sessionStorage.setItem('pool', JSON.stringify({id:id, desc:desc, web:web, twitter:twitter, tele:tele, 
      //     discord:discord, name:"Tapcoins", token:"TTT", supply:"1,500,000,000", status:"Open in TBA"}))
      //   // history.push('/WalletConnect');
      // }else if(id==5){
      //   sessionStorage.setItem('pool', JSON.stringify({id:id, desc:desc, web:web, twitter:twitter, tele:tele, 
      //     discord:discord, name:"Bitcrush", token:"CRUSH", supply:"30000000", status:"Open in TBA"}))
      //   history.push('/pool_screen');
      // }
      // else{
      //   sessionStorage.setItem('pool', JSON.stringify({id:id, desc:desc, web:web, twitter:twitter, tele:tele,
      //      discord:discord, name:"Zenchain Protocol", token:"ZEN", supply:"30000000", status:"Open in TBA"}))


      //   // history.push('/WalletConnect');
      // }

    
        history.push('/depositeWithdrawShot/'+_id);
   

    // }, 2000);
  }

  return(
    <Col lg="6" md="12" xs="12">
    {/* <a href="/poolDetail"> */}
      <div className={styles.whole} >
        <div className={`${styles.card} ${styles.top}`}>
        <Row className={styles.top}>
          <Col xl={4} lg="12" md="12" xs="12" className={styles.logo} onClick={()=>openPool(
        id,
        description,
        website,
        twitterLink,
        telegramLink,
        discordLink,
        )}>
            <a>
            
              <img  src={logo} alt="Logo" className={styles.maruImg} />
        
     




            </a>
          </Col>
          <Col
            className={`d-flex justify-content-center my-4 my-xl-0  align-items-center ${styles.social}`}
            onClick={()=>{}}
          >
            <a
              target="_blank"
              href={website}
              rel="noreferrer"
            >
              <button className="circle button">
                <img src={globe} alt="tapcoin" />
              </button>
            </a>
            <a
              target="_blank"
              href={twitterLink}
              rel="noreferrer"
            >
              <button className="circle button">
                <img src={twitter} alt="twitter" />
              </button>
            </a>
            <a
              target="_blank"
              href={telegramLink}
              rel="noreferrer"
            >
              <button className="circle button">
                <img src={telegram} alt="telegram" />
              </button>
            </a>
            <a
              target="_blank"
              href={discordLink}
              rel="noreferrer"
            >
              <button className="circle button">
                <img src={discord} alt="discord" />
              </button>
            </a>
          </Col>
          <Col
            lg="4"
            xs="12"
            className="d-flex align-items-center flex-column justify-content-center"
          >
            <div
              className={
                status === "2" ? styles["badge-pink"] : styles["badge-blue"]
              }
            >
            {statusArray[status]}
            </div>
            <div className={`${styles.text}`}>BUSD</div>
          </Col>
        </Row>
      </div>
        <div className={`${styles.card} ${styles.bottom}`} onClick={()=>openPool(
        id,
        description,
        website,
        twitterLink,
        telegramLink,
        discordLink,
        )}>
        <Row>
          <Col xs="12" className={styles.des}>
            <p className={styles.note}>{description}</p>
          </Col>
          <Col xs="12">
            <Row>
              <Col md="6" xs="12">
                <div className={styles.label}>{currencyRateType}</div>
                <div className={styles.value}>
                  <span className="highlight inverse">{currencyRate}</span>
                </div>
              </Col>
              <Col md="3" xs="12" className="my-4 my-md-0">
                <div className={styles.label}>CAP</div>
                <div className={styles.value}>
                  <span className="highlight inverse">{cap}</span>
                </div>
              </Col>
              <Col md="3" xs="12">
                <div className={styles.label}>Access</div>
                <div className={styles.value}>
                  <span className="highlight inverse">{acess}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="12" className="mt-3">
            <div>IDO Progress</div>
            <Progress value={fundRaisingProgress} />
            <Row>
              <Col md={{ size: true, order: 1 }} xs={{ size: 6, order: 1 }}>
                <span className="text-pink">{fundRaisingProgress}%</span>
              </Col>
              <Col
                className="text-center mt-3 mt-md-0"
                md={{ size: true, order: 2 }}
                xs={{ size: 12, order: 3 }}
              > 

              {id < 7 && 
                <span>
                Participants <span className={styles.participants}>{id==6?_participants:participants}</span>
              </span>
              }
              {id >= 7 && 
                  <span>
                  Max Investment <span className={styles.participants}>{maxInv}</span>
                </span>
              }
              
              </Col>
              <Col
                md={{ size: true, order: 3 }}
                xs={{ size: 6, order: 2 }}
                className="text-right"
              >
                <span className="text-pink">{renderCompletion()}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      </div>
    {/* </a> */}
  </Col>
  );
}

export default PoolCardShot;
