import React, { useState } from "react";
import { Col, Row, Container, Modal, ModalBody} from "reactstrap";

import styles from "./rewardsPage.module.scss";
import DepositCard from "../../components/depositCard/depositCard";
import RewardsCard from "../../components/rewardsCard/rewardsCard";
import StatsCard from "../../components/statsCard/statsCard";
import WithdrawCard from "../../components/widthrawCard/withdrawCard";

const RewardsPage = () => {
  const [isOpen, setOpen]= useState(true);

  return(
    <div className={styles.rewards}>
    <section>
      <Modal isOpen={isOpen}
          contentClassName={styles.content}
        >
        <ModalBody className={styles.modal}>
          {/* <div>
            <h5>JOIN TAPCOIN POOL</h5>
          </div> */}
          <Row>
            {/* <Col xs="4">BUSD Amount</Col> */}
            <Col xs="8">
              <p>This is an agreement</p>
            </Col>
          </Row>
          <Row className={styles.btnAlgn}>
            <button className="button" onClick={()=>setOpen(false)}>Agree</button>
          </Row>
        </ModalBody>
      </Modal>
      <Container>
        <Row>
          {/* <Col md xs="12"> */}
          <Col className="pt-5 pl-md-5 mb-5 mb-md-0" md xs="12">
            <h2 className={styles.title}>Deposit</h2>
            {/* <h5 className={`${styles.helper} mb-3`}>Deposit NAUT-BNB LP tokens to earn NAUT rewards.</h5> */}
            <DepositCard />
          </Col>
        </Row>
        <Row>
          <Col className="pt-5 pl-md-5 mb-5 mb-md-0" md xs="12">
            <h2 className={`${styles.title} mb-3`}>Withdraw</h2>
            <WithdrawCard />
            {/* <StatsCard />
            <h2 className={styles.title}>Stats</h2> */}
          </Col>
           {/* <Col md="4" xs="12" className="d-flex justify-content-center flex-column">
            <h2 className={styles.title}>Total Claimed<br /> Rewards</h2>
            <p className={styles.total}>
              <span className="highlight">500 <sup className="highlight">NAUT</sup></span>
            </p>
          </Col> */}
          {/* <Col>
            <RewardsCard />
          </Col> */}
        </Row>
      </Container>
    </section>
  </div>
  );
}

export default RewardsPage;
