import React, { useState } from "react";
import Chainsafer from "../components/chainsafer/chainsafer";
import FAQ from "../components/faq/faq";
import Features from "../components/features/features";
import MarketMakers from "../components/marketMakers/marketMakers";
import Tiers from "../components/tiers/tiers";
import Tokenomics from "../components/tokenomics/tokenomics";
import Welcome from "../components/welcome/welcome";
import CountDown from "../components/ComingSoon/";
import { Switch, Route } from "react-router-dom";
import RewardsPage from "./rewardsPage/rewardsPage";
import LockerPage from "./lockerPage/lockerPage";
import DetailPage from "./detailPage/detailPage";
import RocketList from "./detailPage/rocketList";
import ShotList from "./detailPage/shotList";

import Game from "../components/game/Game";
import PoolDetail from "../pages/PoolDetail/poolDetail";
import WalletConnect from "../pages/walletConnect/walletConnect";
import PoolScreenPage from "./poolScreen/poolScreen";
import RocketScreenPage from "./poolScreen/rocketScreen";

import TransactionPage from "./transactionPage/transaction";
import DepositeWithdrawPage from "./depositeWithdraw/depositeWithdraw";
import DepositeWithdrawShot from "./depositeWithdraw/depositeWithdrawShot";
import StakePage from "./stakingPage/stakePage";

const HomePage = () => {
  return (
    <>
    
      <Switch>
        <Route exact path="/">
          <Welcome />
          <Features />
          <Chainsafer />
          <Tiers />
          <Tokenomics />
          <MarketMakers />
          <FAQ />
        </Route>
        <Route exact path="/app">
          <CountDown />
        </Route>
        <Route exact path="/deposit">
          <RewardsPage />
        </Route>
        <Route exact path="/locker">
          <LockerPage />
        </Route>
        <Route exact path="/pool">
          <DetailPage />
        </Route>
        <Route exact path="/stake">
          <StakePage />
        </Route>
        <Route exact path="/astrorocket">
          <RocketList />
        </Route>
        <Route exact path="/astroshot">
          <ShotList />
        </Route>
        
        <Route exact path="/rocket_screen/:id">
          <RocketScreenPage />
        </Route>
        
        <Route exact path="/game">
          <Game />
        </Route>
        <Route exact path="/poolDetail">
          <PoolDetail />
        </Route> 
        <Route exact path="/WalletConnect">
          <WalletConnect />
        </Route>
        <Route exact path="/pool_screen/:contract/:staking">
          <PoolScreenPage />
        </Route>
        <Route exact path="/staking/:contract">
          <TransactionPage />
        </Route>
        
        <Route exact path="/depositeWithdrawshot/:ido">
          <DepositeWithdrawShot />
        </Route>
        <Route exact path="/depositeWithdraw">
          <DepositeWithdrawPage />
        </Route>
      </Switch>

    </>
  );
};

export default HomePage;
