import React from "react";
import { Col, Container, Row } from "reactstrap";

import styles from "./chainsafer.module.scss";

let Particles = [];

for (let index = 0; index < 1000; index++) {
  Particles.push(<div key={index} className="c" />);
}

const Chainsafer = () => (
  <div className={styles.chainsafer} id="chainsafer">
    <section className={styles.section}>
      <div className={styles.stars}></div>
      <div className={styles.stars2}></div>
      <div className={styles.stars3}></div>
      <Container>
        <Row>
          <Col className="mb-5">
            <h2 className="text-center section-title">
            MAKING FANTOM SAFER
            </h2>
            <p className="text-center ">
            The Fantom chain is a great way for investors to get lower transaction fees and faster speeds. However, constant rugs and exit scams are currently hurting the progress of Defi on the Fantom chain
            </p>
            <p className="text-center ">
            Astronaut is created with a clear focus on solving these problems and bringing a sense of security, which this space desperately craves. We're aiming to build a platform that ensures the safety of all funds, for both investors and projects.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className={styles.facts}>
            <div className={styles.fact}>
              <h4 className="">INNOVATIVE TOKEN LOCKS</h4>
              <p className="">
              The Astronaut IDO’s will require new projects to lock a large share of their liquidity
              and team tokens on our third-party partner’s locker platform. We make it easy, safe,
              and secure to lock any ERC-20 based token in seconds protecting investors from ‘rug pulls’.

              </p>
            </div>
            <div className={styles.fact}>
              <h4 className="">THE SAFEST LAUNCHPAD ON FANTOM</h4>
              <p className="">
              Astronaut enables promising projects to raise capital on the FANTOM. We make sure investors are safe to invest in early-stage projects through our KYC system and strict due diligence. Only audited, carefully vetted, and analyzed blockchain projects will be chosen.
              </p>
            </div>
            <div className={styles.fact}>
              <h4 className="">ASTRONAUT CONTRACT CHECKS</h4>
              <p className="">
              Our Astronaut developers are experienced in all types of smart contract code. We will check for exploits and vulnerabilities in any smart contracts, such as Minting, Proxies, and Backdoors.
              </p>
            </div>
            <div className={styles.fact}>
              <h4 className="">TIERED FCFS IDO FLOW SUMMARY</h4>
              <p className="">
              Astronaut has a unique Tiered-FCFS (First Come/First Served) System implemented which requires users to simply hold a certain amount of fNAUT tokens in their wallet to participate in 
              </p>
            </div>
          </Col>
          <Col xs={12} lg={6} className={styles.network}>
            {/* <div className="wrap">{Particles}</div> */}
            <div className={styles.rocket}>
              <div className={styles.rocket_body}>
                <div className={styles.body}></div>
                <div className={styles.fin_left}></div>
                <div className={styles.fin_right}></div>
                <div className={styles.window}></div>
              </div>
              <div className={styles.exhaust_flame}></div>
              <ul className={styles.exhaust_fumes}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default Chainsafer;
