import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router }from 'react-router-dom';
import {UseWalletProvider} from '@binance-chain/bsc-use-wallet'
// import { UseWalletProvider } from 'use-wallet'
import Config from "./BlockchainProvider/config"
ReactDOM.render(
  <React.StrictMode>
    <Router>

      <UseWalletProvider
       connectors={{
        walletconnect: {rpcUrl :"https://data-seed-prebsc-1-s1.binance.org:8545/"}
       }}
      chainId={Config.CHAIN_ID}>
      <App />
      </UseWalletProvider>
      
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
