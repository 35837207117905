import React from "react";
import { Col, Row, Container } from "reactstrap";

import TokenMetrios from "../tokenMetrios/tokenMetrios";
import styles from "./tokenomics.module.scss";

const Tokenomics = () => (
  <div className={styles.tokenomics} id="tokenomics">
    <section>
      <Container>
        <Row>
          <Col lg="5" md={12}>
            <h2 className="text-center text-md-left section-title">
              fNAUT <br></br>TOKENOMICS
            </h2>
            <p className="text-center text-md-left">
            The native token of Astronaut on the Fantom chain is an ERC-20 token
designated fNaut. It is a fixed supply token to be used for our platform’s
IDO tier system.

            </p>
            <a
              className="d-none d-md-inline"
              target="_blank"
              href="https://darkdex.knightswap.financial/#/swap?outputCurrency=0x9c11cA3B1012bb752E1B57F939E3A86AF354675c"
              rel="noreferrer"
            >
              <button className="button">Buy NAUT</button>
            </a>
          </Col>
          <Col lg={7} md="12">
            <TokenMetrios />
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default Tokenomics;
