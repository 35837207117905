import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import styles from "./withdrawPage.module.scss";
import config from "../../BlockchainProvider/config";
 

const WithdrawPage = (props) => {



 
 
  const [stakingBalance, setStakingBalance] = useState("-");
  const [tier,setTier] = useState("-");
  const [stakingContract, setStakingContract] = useState();
  const [isIcoOver, setICOOver] = useState();

  useEffect(() => {
    if (props.stakingContract) {
      setStakingContract(props.stakingContract);
      loadData(props.stakingContract)
    }

  }, [props.stakingContract])






  const loadData = async (contract) => {
    
    let _stakerData = await contract.methods.getStaker(props.account).call();

    let _isICOOver =  await contract.methods.ICOover().call();

    setICOOver(_isICOOver)
    let _stakingAmount =  _stakerData.amount/10**18;
    let _tier = _stakerData.tier;

    if(_tier === "1"){
      setTier("Moon")
    }else  if(_tier === "2"){
      setTier("Mars")
    }else  if(_tier === "3"){
      setTier("Star")
    }if(_tier === "4"){
      setTier("Galaxy")
    }
    
    setStakingBalance(_stakingAmount);
  }







  const handleWithdraw = async (amount) => {

    if(isIcoOver == false){

      alert("ICO is not over yet!")
      return
    }
    await stakingContract.methods.unStakeTokens()
    .send({ from: props.account });

  }



  useEffect(() => {
    setInterval(() => {
      if (stakingContract) {
        loadData(stakingContract)
      }
    }, 2000)
  }, [])




  const renderWithdrawButtonText = ()=>{
      if(props.account){
          return "Withdraw";
      }else{
        return "Connect Wallet"
      }
  }




  return <section className={styles.section1}>
  <Container>
  <Row className={styles.card1}>
    <Col lg="8" md="12" xs="12">
      <Row>
        <Col md="6">
          <p className={styles.title}>Total Staked Amount</p>
          <p className={styles.value}>{stakingBalance}</p>
        </Col>
        <Col md="6">
          <p className={styles.title}>Tier</p>
          <p className={styles.value}>{tier}</p>
        </Col>
      </Row>
      {/* <Row>
      <Col md="6">
          <p className={styles.title}>Total LP Amount</p>
          <p className={styles.value}>$8035.62</p>
        </Col>
        <Col md="6">
          <p className={styles.title}>Total LP Amount</p>
          <p className={styles.value}>$8035.62</p>
        </Col>
      </Row> */}
    </Col>
    <Col lg="4" md="12" xs="12">
      <div className={styles.btnTab}>
        {/* <p className={styles.text}>4% Fee</p> */}
        <button className="button"  onClick={()=>{
          if(isIcoOver){
            handleWithdraw()

          }else{
              alert("ICO is not over yet")
          }
        }}>{renderWithdrawButtonText()}</button>
      </div>
    </Col>
  </Row>
 

</Container>
</section>
}

export default WithdrawPage;
