module.exports={


    //=====TESTNET======
    
    // STAKING_CONTRACT :"0x4c7288EE6257166eF0a2D04cb18dbED2Df259f5b",
    // NAUT_CONTRACT:"0x8a7c3b23e7a1f6f2418649086097b68ed67a41db",
    // ICO_CONTRACT:"0xCa79d5aa5eb2097326EB918FfAa9ceB895c61Dc5",
    // BUSD_CONTRACT:"0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47",
    // NEW_STAKING:"0x2A67A43CE7e5A1F287F08D910bD20f0219edFcdC",
    // NEW_IDO:"0xAE8CAfa79DD1092Bf1c4Be6FE7550209C0F7C1D9",
    // FCFS_CONTRACT_NEW: "0x60F8d30885A4c17599046f66Ec6fdAbC794356a7",


    STAKING_CONTRACT :"0xec85FfdC6C6646a8c3aa032b0A319656e0de19BE", 
    NAUT_CONTRACT:"0xca469963a030A3670Ed76832A6A181d280af108D",
    BUSD_CONTRACT:"0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    NEW_STAKING:"0xec85FfdC6C6646a8c3aa032b0A319656e0de19BE",
    NEW_IDO:"0xD8102ab9E94382dC11C6c57EDE35b48E6C915322",
    FCFS_CONTRACT_NEW: "0x8CCAf87f42aAD55b6D8161451DeC33bcca85e482",
    WOLF_NEW_IDO:"0xAE377F72Eee8d6332B0B2EC319DcAEF942317050",
    NEW_IDO_SHOT:"0x6A5Ff2478e8A5a298696A0BB779C1f63bf4D77a2",
    API_URL : "https://astronaut-dashboard-api.herokuapp.com/api",
    
    ///////////////////////////////////////////////////////////
    // STAKING_CONTRACT :"0xf0319eD6E918f8167f53Bc12151Dc989025516D0",
    // // NAUT_CONTRACT:"0xf0319ed6e918f8167f53bc12151dc989025516d0",
    // ICO_CONTRACT:"0xaa7e9de8f79f20a49b620ba9f38f541c3da5396e",
    // // BUSD_CONTRACT:"0x8301f2213c0eed49a7e28ae4c3e91722919b8b47",
    // FCFS_CONTRACT:"0x89c8843ac4b6699ce51a06f1883f8a1e93bce6b4",
    ///////////////////////////////////////////////////////////
    LOCKED_DAYS : 1*24*60*60, //days in second



    CHAIN_ID:250 // use 56 for mainnet

}