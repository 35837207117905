import React from "react";
import { Col, Row, Container } from "reactstrap";
import styles from "./schedulePage.module.scss";
const Schedule = () => (
  <Container>
    <Row>
      <Col xs="12">
        <div className={styles.card}>
          <Row>
             <Col xs="4">ROUND</Col>
             <Col xs="4">START </Col>
             <Col xs="4">CLOSES</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="4">Allocation</Col>
             <Col xs="4">TBD</Col>
             <Col xs="4">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="4">FCFS - Prepare</Col>
             <Col xs="4">TBD</Col>
             <Col xs="4">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="4">FCFS - Start </Col>
             <Col xs="4">TBD</Col>
             <Col xs="4">TBD</Col>
          </Row>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Schedule;
