import React, { useEffect, useState } from "react";

import styles from "./navigation.module.scss";

const NavigationButton = ({ onClick, iconUrl, name, isActive, style }) => (
  <button
    onClick={() => onClick(name)}
    className={isActive && styles.active}
    style={{ ...style }}
  >
    {iconUrl && <img src={iconUrl} width="20" alt="icon" />}
    <span>{name}</span>
  </button>
);



const Navigation = ({ handleOnClick, activePage ,tickerTimer,tickerText}) => {
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    
    if(tickerTimer && tickerTimer > 0){
      
      var deadline = new Date(tickerTimer*1000).getTime();

      initTimer(deadline)
    }
 
  }, [tickerTimer])

  const initTimer=(deadline)=>{
    var interval = setInterval(function () {
      var now = new Date().getTime();
      var t = deadline - now
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((t % (1000 * 60)) / 1000);
      setRemainingTime(days + "d "
      + hours + "h " + minutes + "m " + seconds + "s ");
      if (t < 0) {
        clearInterval(interval);
        setRemainingTime("")
      }
    }, 1000);
  }


  return (
    <div className={styles.navigation}>
      <div style={{display:"flex"}}>
      <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="Deposit"
        isActive={activePage === "Deposit"}
      />
      <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="Withdraw"
        isActive={activePage === "Withdraw"}
      />
      </div>
    



      <div style={{ width: "100%" }}>
      <div
          class={styles.timer}
          >

           <span style={{fontSize:22,fontWeight:"bold",marginLeft:10,marginRight:10}}>
           {tickerText?`${tickerText} ${remainingTime}`:null}
           </span>



          </div> 
      </div>
    </div>
  );
};

export default Navigation;
