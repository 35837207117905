import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import logo2 from "../../images/logo3.png"

import styles from './poolScreen.module.scss';
import {useWallet} from '@binance-chain/bsc-use-wallet'
import {useParams } from 'react-router-dom';

import config from "../../BlockchainProvider/config"
import ICO_ABI from "../../BlockchainProvider/ABI/NEW_IDO.json"
import STAKING_ABI from  "../../BlockchainProvider/ABI/NEWSTAKING.json"
import NAUT_ABI from "../../BlockchainProvider/ABI/NAUT_ABI.json"
import Web3 from "web3"
import moment from "moment";
 
import NJW from "../../images/NJW.png";
import WELT from "../../images/logo3.png";
  
const PoolScreenPage = () => {

  const { contract,staking } = useParams();

  const [icoContract, setIcoContract] = useState(null)
  const [startDate, setStartDate] = useState("-")
  const [endDate, setEndDate] = useState("-");

  const [vetsingP, setVestingP] = useState("-");
  const [vetsingR, setVestingR] = useState("-");


  const [moonLimit, setMoonLimit] = useState("-")
  const [marsLimit, setMarsLimit] = useState("-")
  const [starLimit, setStarLimit] = useState("-")
  const [galaxyLimit, setGalaxyLimit] = useState("-")

  const [isBuyEnabled, setBuyEnabled] = useState(false)
  const [isClaimEnabled, setClaimEnabled] = useState(false)

  const [myBalance, setMyBalance] = useState("-")
  const [myMaxContribution, setMyMaxContribution] = useState("-")
  const [myRemainingContribution, setMyRemainingContribution] = useState("-")
  const [myContribution, setMyContribution] = useState("-")
  const [myMaxLimit, setMyMaxLimit] = useState()
  const [amountInput, setAmountInput] = useState()
  const [currentTierName, setCurrentTierName] = useState("-")

  const [icoTarget, setIcoTarget] = useState()
  const [receivedFund, setReceivedFund] = useState()

  const [fundRaisingProgress, setFundRaisingProgress] = useState(100)

  const [participants,setParticipant] = useState();

  const [busdBalance,setBUSDBalance] = useState("-");



  const [claimAmount, setClaimAmount] = useState("-")

  const [busdContract,setBusdContract] = useState("-");
  const [busdAllowance,setBUSDAllowance] = useState("-");
  const [expireTime, setExpireTime] = useState();


  const [remainingTime, setRemainingTime] = useState();

  useEffect(() => {
    if(!expireTime){
      return
    }

    var deadline = new Date(expireTime*1000).getTime();
    if(isClaimEnabled){
      setRemainingTime("Claim Tokens")

    }else{
      initTimer(deadline)

    }
  }, [expireTime])

  const initTimer=(deadline)=>{
    var interval = setInterval(function () {
      var now = new Date().getTime();
      var t = deadline - now;
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((t % (1000 * 60)) / 1000);
      setRemainingTime(days + "d "
      + hours + "h " + minutes + "m " + seconds + "s ");
      if (t < 0) {
        clearInterval(interval);
        setRemainingTime("Finished")
      }
    }, 1000);
  }


  const wallet = useWallet();

  
  let SampleData = {}

    
  SampleData['0xD547A151304D616eE64EE8A4b8aA8Ab7ff48fEC6']  = {
   
      id: 2,
      description:
        "Ninja’s World is a blockchain-based Ninja adventure game. Players are able to adventure into the Ninja’s World to engage in battles, daily tasks and upgrade the rank in the arena to earn abundant token rewards.",
      status: "Open",
      currencyRateType:"$NJW/USDC",
      currencyRate: "1USDC= 33.33 $NJW",
      symbol: "$NJW",
      cap: "150k",
      acess: "Public",
      participants: "3075",
      percentage: "100%",
      completion: "2,000,000",
      website:"https://ninjasworld.me/",
      twitterLink:"https://twitter.com/NinjaInMeta",
      telegramLink:"https://t.me/NinjasWorldOfficial",
      discordLink:"#",
      contract: "0xD547A151304D616eE64EE8A4b8aA8Ab7ff48fEC6",
      staking: "0x7F0B42D3DB9CaD8E8a7fF91097CA5C3772A1536F",
      image: NJW,
      closed:false

    } ; 
    
    SampleData['0xD8102ab9E94382dC11C6c57EDE35b48E6C915322']  = {
      
      id: 1,
      description:
        "FABWELT creates a gaming platform that brings blockchain technology into the core of high quality games of all types or genres. We achieve this by utilizing the finest tech of both worlds, beautiful high-quality games and the latest blockchain technology.",
      status: "Closed",
      currencyRateType:"pNAUT/USDC",
      currencyRate: "1USDC= 71.42 WELT",
      symbol: "$WELT",
      cap: "100k",
      acess: "Public",
      participants: "3075",
      percentage: "100%",
      completion: "2,000,000",
      website:"https://www.fabwelt.com/",
      twitterLink:"https://twitter.com/FabweltToken",
      telegramLink:"https://t.me/FabweltToken",
      discordLink:"https://discord.gg/5DyazH7vPj",
      contract: "0xD8102ab9E94382dC11C6c57EDE35b48E6C915322",
      staking: "0xec85FfdC6C6646a8c3aa032b0A319656e0de19BE",
      image: WELT,
      closed:true

    };
  

  const initContracts = async () => {
    let _web3 = new Web3(window.ethereum);
    const _icoContract = new _web3.eth.Contract(ICO_ABI, contract)
    let _sDate = ''
    let _eDate = ''

    let stakingDetails = await _icoContract.methods.ico(wallet.account).call();

    let _vestingP = await _icoContract.methods.vestingperc().call();
    let _vestingR = await _icoContract.methods.vestingTime().call();
    setVestingP(_vestingP);
    setVestingR(_vestingR);

    let myMaxLimit = await _icoContract.methods.maximumContribution(wallet.account).call();
    setMyMaxLimit((myMaxLimit / 1e6))
    let remainingContri = await _icoContract.methods.remainigContribution(wallet.account).call();
    setMyRemainingContribution((remainingContri / 1e6).toFixed(4));

   
    setMyContribution((stakingDetails.investedamount / 1e6).toFixed(4));

    let  myMaxContribution= await _icoContract.methods.maximumContribution(wallet.account).call();
    setMyMaxContribution((myMaxContribution / 1e6).toFixed(4));

    // let _tokenPrice = await _icoContract.methods.tokenPrice().call();
    let _claimAmount = (stakingDetails.remainingClaim / 1e18);
    setClaimAmount(_claimAmount);

    let _moonLimit = await _icoContract.methods.getTier1Maxlimit().call();
    let _marsLimit = await _icoContract.methods.getTier2Maxlimit().call();
    let _starLimit = await _icoContract.methods.getTier3Maxlimit().call();
    let _galaxyLimit = await _icoContract.methods.getTier4Maxlimit().call();

    let icoStatus = await _icoContract.methods.icoStatus().call();


    if(icoStatus === true){
      setBuyEnabled(true)
    }


    let _claimEnabled = await _icoContract.methods.claimenabled().call();

    setClaimEnabled(_claimEnabled)



   setExpireTime(_eDate)


    let _icoTarget = await _icoContract.methods.icoTarget().call();
    setIcoTarget((_icoTarget/1e6).toFixed(0));


    let _receivedFund = await _icoContract.methods.receivedFund().call();
    setReceivedFund((_receivedFund/1e6).toFixed(0));


    let progressPercent = (_receivedFund/_icoTarget)*100
    if(progressPercent<20){
      progressPercent = 20
    }
    setFundRaisingProgress(progressPercent)


    initializeParticipantCount(_web3)




    _moonLimit  = (_moonLimit/1e18).toFixed(0)
    _marsLimit  = (_marsLimit/1e18).toFixed(0)
    _starLimit  = (_starLimit/1e18).toFixed(0)
    _galaxyLimit  = (_galaxyLimit/1e18).toFixed(0)



    let _currentTier = await _icoContract.methods.checkyourTier(wallet.account).call();
    if(_currentTier == 1){
      setCurrentTierName("Moon")
    }else  if(_currentTier == 2){
      setCurrentTierName("Mars")
    }else  if(_currentTier == 3){
      setCurrentTierName("Start")
    }if(_currentTier ==4){
      setCurrentTierName("Galaxy")
    }




    setMoonLimit(_moonLimit);
    setMarsLimit(_marsLimit);
    setStarLimit(_starLimit);
    setGalaxyLimit(_galaxyLimit);




    _sDate = moment(_sDate * 1000).format("MMM DD YYYY | hh:mm:ss");
    _eDate = moment(_eDate * 1000).format("MMM DD YYYY |  hh:mm:ss");

    setIcoContract(_icoContract);
    setStartDate(_sDate);
    setEndDate(_eDate);







    await initBUSDBalance(_web3)


  }



  const initBUSDBalance= async(web3)=>{
    const busdBalance = new web3.eth.Contract(NAUT_ABI, config.BUSD_CONTRACT);
    let _balance = await busdBalance.methods.balanceOf(wallet.account).call();
    let _allowance = await busdBalance.methods.allowance(wallet.account,contract).call();

    setBUSDBalance((_balance/1e6).toFixed(3))

    setBUSDAllowance(_allowance);
    setBusdContract(busdBalance)
    
  }


  const initializeParticipantCount = async(web3)=>{
    const participantsContracts = new web3.eth.Contract(STAKING_ABI, staking);
    let participantCounts = await participantsContracts.methods.countStakers().call();

    setParticipant(participantCounts)
  }


  useEffect(() => {
    if (wallet.status === 'connected') {
      initContracts();
    }
  }, [wallet]);


  const handleBuy=async()=>{



    if(!isBuyEnabled){

      alert("BUSD staking is not live ")
      return
    }


    if(!amountInput){
      alert("Please Enter A Valid Amount");
      return
    }
    // let amount = Web3.utils.toWei(amountInput.toString());
    let amount = amountInput * 1000000;

    console.log("amounttt",busdAllowance,amount)
    if(amount > busdAllowance){
      await busdContract.methods.approve(contract, "1000000000000000000000000000000000000")
      .send({ from: wallet.account }).then(async(re)=>{
  
        await icoContract.methods.Investing(amount)
        .send({ from: wallet.account });
      });
    }else{
      await icoContract.methods.Investing(amount).send({from:wallet.account});

    }

  }

  const handleClaim=async ()=>{
    if(!isClaimEnabled){
      alert("Claim is not enabled yet ")
      return
    }
    await icoContract.methods.claimTokens().send({from:wallet.account}).then(()=>{
      setClaimAmount(0)
    });

  }


  function truncateToDecimals(num, dec = 2) {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
  }


  return <>
    <div className={styles.faq}>
      <section>
        <Container>
          <div className={styles.mainBox}>
            <div className={styles.box}>
              <div className={styles.boxImg}>
                <img className={styles.bitcush } src={SampleData[contract].image} alt="logo3" class="w-80" />
              </div>
              <div>
                <p style={{fontSize:20}}></p>
                <p>{SampleData[contract].description}
      </p>
                {/* <p>The First Hybrid Defi Gaming <br />Platform on BSC</p>
                <p>A hybrid system of provably fair and decentralized<br />
                games that utilize Defi protocols, launching with a<br /> tested and working product.</p> */}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
    <div className={styles.allocation}>
      <div className={styles.allocationBox}>
        <div className={styles.date}>
          <p><span> Vesting %tage </span></p>
          <p className={styles.dateSize}>{vetsingP}</p>
          <div className={styles.alignBox}>
            <p><span> Vesting release</span></p>
            <p className={styles.dateSize}>{vetsingR}</p>
          </div>



        </div>
        <div className={styles.price}>
          <div className={styles.priceSale}>
            <div><p><span> Sale Price </span></p>
              <p className={styles.dateSize}>{SampleData[contract].currencyRate}</p>
            </div>
            <div>
              <p><span> Token Ticker </span></p>
              <p className={styles.dateSize}>{SampleData[contract].symbol}</p>
            </div>
          </div>
          <div className={styles.alignBox}></div>
          <div className={styles.priceSale}>
            <div>
              <p><span> IDO Hardcap </span></p>
              <p className={styles.dateSize}>{SampleData[contract].cap}</p>
            </div>
            <div>
              <p><span> Participants </span></p>
              <p className={styles.dateSize}>{participants}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.allocationLimit}>
        <h4 className={styles.limit}>Allocation Limit</h4>
        <div className={styles.moonBox}>
          <div>
            <div className={styles.moon}>
              <p className={styles.nameMoon}>Moon</p>
              <p className={styles.BUSD}>{moonLimit} {SampleData[contract].symbol}</p>
            </div>
          </div>
          <div>
            <div className={styles.moon}>
              <p className={styles.nameMoon}>Mars</p>
              <p className={styles.BUSD}>{marsLimit} {SampleData[contract].symbol}</p>
            </div>
          </div>
          <div>
            <div className={styles.star}>
              <p className={styles.nameStar}>Star</p>
              <p className={styles.BUSD}>{starLimit} {SampleData[contract].symbol}</p>
            </div>
          </div>
          <div>
            <div className={styles.star}>
              <p className={styles.nameStar}>Galaxy</p>
              <p className={styles.BUSD}>{galaxyLimit} {SampleData[contract].symbol}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.wallet}>
      <Container>
       
     
        <div className={styles.balance}>
          
        {remainingTime?  <div
          class={styles.timer}>

           <span style={{fontSize:14,fontWeight:"bold",marginLeft:10,marginRight:10}}>
           {`Timer: ${remainingTime}`}
           </span>
          </div>:null}
          
          <div className={styles.yourBalance} style={{marginTop:20}}>
            <div>
              <p><span> Your Balance </span></p>
              <p className={styles.dateSize}>{busdBalance} USDC</p>
            </div>

           
            <div>
              <p><span> Your maximum Contribution </span></p>
              <p className={styles.dateSize}>{myMaxContribution} USDC</p>
            </div>


           
          </div>
          <div className="progress" style={{ background: `linear-gradient(to right, #df05ff 0%, #0968b8 100%)`, 
      
          margin: "30px 0 0 0", borderRadius: "20px" }}>
            <span style={{ margin: "8px 0px 0 15px", fontSize: "10px" }}>Progress ({receivedFund}/{icoTarget})</span>
            {/* <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
          </div> */}
          </div>
       
       
       
       
          <div className={styles.yourBalance} style={{ margin: "25px 0 0 0" }}>
            <div>
              <p><span> Your Contribution: </span></p>
              <p className={styles.dateSize}>{myContribution} USDC</p>
            </div>

           


            <div>
              <p><span> Remaining Contribution </span></p>
              <p className={styles.dateSize}>{myRemainingContribution} USDC</p>
            </div>
          </div>
          <div>
            <div className={styles.amountfield}>
              <input type="text" className={styles.txtAgn} placeholder="Enter Amount" value={amountInput} onChange={(e)=>{
                setAmountInput(e.target.value)
              }}/>
              <button className={styles.buttonBlue}>USDC</button>
              <button className={styles.buttonMax} onClick={()=>{
                setAmountInput(truncateToDecimals(myMaxLimit,2))
              }}>MAX</button>
              
              <button className={styles.buttonBuy} onClick={()=>{
                handleBuy();
              }}>Buy</button>
            </div>

          </div>
          <div className={styles.claim}>
            <input type="text" value={`Claim Amount:${claimAmount}`} className={styles.txtAgn} placeholder="Enter Amount" disabled />
            <button className={styles.buttonBuy} onClick={()=>{
              handleClaim()
            }}>Claim</button>
          </div>
        </div>
      </Container>
    </div>
  </>
};

export default PoolScreenPage;
