import React from "react";
import { Col, Row, Container } from "reactstrap";

import styles from "./footer.module.scss";
import Logo from "../../images/typographic-gr.png";
import Helmet from "../../images/icons/helmet-large-gr.png";
import twitter from "../../images/icons/twitter.png";
import twitterblack from "../../images/icons/twitterblack.png";
import telegram from "../../images/icons/telegram.png";
import blacktelegram from "../../images/icons/blacktelegram.png";
import medium from "../../images/icons/medium.png";
import blackmedium from "../../images/icons/blackmedium.png";
import poly from "../../images/icons/poly.png";
import blackpoly from "../../images/icons/blackpoly.png";
// import unknown from "../../images/icons/unknown.png";
import unknown from "../../images/icons/global.png";
import globalw from "../../images/icons/globalw.png";
import bsc1 from "../../images/bsc1.png";

const Footer = () => (
  <div className={styles.footer}>
    <section>
      <Container>
        <Row>
          <Col xs="12" className={styles.logos}>
            <a href="/">
              <img src={Helmet} className={styles.helmet} alt="helmet" />
              <img className={styles.logo} src={Logo} alt="Logo" />
            </a>
          </Col>
          <Col md={6} lg={7} className={styles.links}>
            <Row>
              <Col>
                <h4>Support</h4>
                <a
                  target="_blank"
                  href="https://astronaut-1.gitbook.io/astronaut/" rel="noreferrer"
                >
                  Whitepaper
                </a>
                <a
                  target="_blank"
                  href="https://astronaut-1.gitbook.io/astronaut/01.-what-is-astronaut/who-is-the-astronaut-team" rel="noreferrer"
                >
                  Team
                </a>
                <a target="_blank" href="https://astronautfinance.medium.com/" rel="noreferrer">
                  News
                </a>
              </Col>
              <Col>
                <h4>About</h4>
                <a target="_blank" href="https://github.com/astronauttoken/" rel="noreferrer">
                  Docs
                </a>
                <a href="#faq">
                  FAQ
                </a>
                <a
                  target="_blank"
                  href="./AstronautPrivacyPolicy.pdf" rel="noreferrer"
                >
                  Privacy
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={5} className={styles.social}>
            <h4>Stay Updated</h4>
            <div className={styles.buttons}>
            <a
                  target="_blank"
                  href="https://twitter.com/astronauttoken"
                  rel="noreferrer"
                >
                  
                  <button className="circle button">
                    <img src={twitter} alt="twitter" className="twitter-white" />
                    <img src={twitterblack} alt="twitter" className="twitter-black" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/astronaut_bsc"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={telegram} alt="telegram" className="twitter-white" />
                    <img src={blacktelegram} alt="telegram" className="twitter-black" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://discord.gg/5DyazH7vPj"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={medium} alt="discord" className="twitter-white" />
                    <img src={blackmedium} alt="discord" className="twitter-black" />
                  </button>
                </a>
                <a target="_blank" href="https://ftmscan.com/address/0x9c11ca3b1012bb752e1b57f939e3a86af354675c" rel="noreferrer">
                  <button className="circle button">
                    <img src={bsc1} alt="binance" className="twitter-white" />
                    <img src={bsc1} alt="binance" className="twitter-black" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://astronaut.to/"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={unknown} alt="Tapcoin" className="twitter-black" />
                    <img src={globalw} alt="Tapcoin" className="twitter-white" />
                  </button>
                </a>
            </div>
            <h5>
              Powered By  <br />  Fantom Chain
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default Footer;
