import React from "react";
import { Col, Row, Container } from "reactstrap";
import styles from "./projectDetail.module.scss";
import { data } from "jquery";
const ProjectDetail = () => {
   const data = JSON.parse(sessionStorage.getItem('pool'));

   return(
   <Container>
    <Row>
      <Col lg="6" md="12" xs="12" className={styles.col}>
        <div className={styles.card}>
          <Row>
            <div>
            <span className={styles.flex13}>Pool Info</span>
            </div>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Opens</Col>
             <Col xs="6">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">FCFS Opens</Col>
             <Col xs="6">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Closes</Col>
             <Col xs="6">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Swap Rate</Col>
             <Col xs="6">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Cap</Col>
             <Col xs="6">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Total Users Participated</Col>
             <Col xs="6">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Total Funds Swapped</Col>
             <Col xs="6">TBD</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Access Type</Col>
             <Col xs="6">Private</Col>
          </Row> 
        </div>
      </Col>
      <Col lg="6" md="12" xs="12" className={styles.col}>
      <div className={styles.card}>
          <Row>
            <div>
              <span className={styles.flex13}>Token Info</span>
            </div>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Name</Col>
             <Col xs="6">{data.name}</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Token Symbol</Col>
             <Col xs="6">{data.token}</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Total Supply</Col>
             <Col xs="6">{data.supply}</Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="6">Decimals</Col>
             <Col xs="6">18</Col>
          </Row>
        </div>
      </Col>
    </Row>
  </Container>
)};

export default ProjectDetail;
