import React, { Component } from "react";
import styles from './depositCard.module.scss';
import { Row, Col } from "reactstrap";


export default class DepositCard extends Component {

  constructor(props) {
      super(props);
      this.state = {
          naut:'',
          walletNaut:0
      }
  }

  componentDidMount(){
    this.setState({naut:'', walletNaut:20});
  }

  setMaxNaut(){
    this.setState({naut:this.state.walletNaut});
  }

  render() {
    return (
  <div className={styles.card}>
    <Row>
      <Col xs={12} className="text-right">
        <p className={styles.label}>Your Balance is</p>
        <p className={styles.value}>
          {this.state.walletNaut} NAUT
        </p>
      </Col>
      <Col xs={12}>
        <input type="text" placeholder="Enter Amount" value={this.state.naut} onChange={(e)=>this.setState({naut:e.target.value})}/>
        {/* <button className={styles.max} onClick={()=>this.setMaxNaut()}>
          <span className={styles.maxContent}>Max</span>
        </button> */}
      </Col>
      <Col xs className="d-flex align-items-center">
        <span className={styles.fee}>2.5% Fee</span>
      </Col>
      <Col xs="auto">
        <button className="button" onClick={()=>this.setState({naut:100})}>Moon</button>
      </Col>
      <Col xs="auto">
        <button className="button" onClick={()=>this.setState({naut:250})}>Mars</button>
      </Col>
      <Col xs="auto">
        <button className="button" onClick={()=>this.setState({naut:600})}>Star</button>
      </Col>
      <Col xs="auto">
        <button className="button" onClick={()=>this.setState({naut:1500})}>Galaxy</button>
      </Col>
      <Col xs="auto">
        <button className="button">Approve</button>
      </Col>
    </Row>
  </div>
  )}
}