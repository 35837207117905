import React, { useState } from "react";
import { Row, Container } from "reactstrap";
import Navigation from "../../components/navigation/navigation";
import AboutPage from "../aboutPage/aboutPage";
import PoolsPage from "../poolsPage/poolsPage";
import TiersPage from "../tiersPage/tiersPage";
// import TiersPage from "../../components/tiers/tiers";
import styles from "./detailPage.module.scss";

const Pages = {
  About: <AboutPage />,
  Tiers: <TiersPage />,
  Pools: <PoolsPage />
};

const DetailPage = () => {
  const [selectedPage, setSelectedPage] = useState("Pools");
  return (
    <div className={styles.details}>
      <section>
        <Container>
          <>
            <Navigation
              handleOnClick={name => setSelectedPage(name)}
              activePage={selectedPage}
            />
          </>
        </Container>
        <div className={styles.panel}>{selectedPage && Pages[selectedPage]}</div>
      </section>
    </div>
  );
};

export default DetailPage;
