import React from "react";
import { Col, Row, Container } from "reactstrap";
import styles from "./allocationPage.module.scss";
const Allocation = () => {
  const data = JSON.parse(sessionStorage.getItem('pool'));
  return(
  <Container className={styles.col}>
    <Row>
      <Col xs="12">
        <div className={styles.card}>
          <Row>
             <Col xs="1" className={styles.algn}><span>No.</span></Col>
             <Col xs="3" className={styles.algn}><span>Token allocation</span></Col>
             <Col xs="3" className={styles.algn}><span>Date</span></Col>
             <Col xs="3" className={styles.algn}><span>Token(s) claimed</span></Col>
             <Col xs="2" className={styles.aln_r}><span>Action</span></Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="1" className={styles.algn}>1</Col>
             <Col xs="3" className={styles.algn}>XXXXX</Col>
             <Col xs="3" className={styles.algn}>XXXXXX</Col>
             <Col xs="3" className={styles.algn}>XXXXXX {data.token}</Col>
             <Col xs="2" className={styles.action}>
               <a href="/pool" className={styles.mt30}>
                 <button className="miniBtn button">Claim Tokens</button>
               </a>
             </Col>
          </Row>
          <Row className={styles.row}>
             <Col xs="1" className={styles.algn}>2</Col>
             <Col xs="3" className={styles.algn}>XXXXX</Col>
             <Col xs="3" className={styles.algn}>XXXXXX</Col>
             <Col xs="3" className={styles.algn}>XXXXXX {data.token}</Col>
             <Col xs="2" className={styles.action}>
               <a href="/pool" className={styles.mt30}>
                 <button className="miniBtn button">Claim Tokens</button>
               </a>
             </Col>
          </Row>
          <Row className={styles.row}>
          <Col xs="1" className={styles.algn}>3</Col>
             <Col xs="3" className={styles.algn}>XXXXX</Col>
             <Col xs="3" className={styles.algn}>XXXXXX</Col>
             <Col xs="3" className={styles.algn}>XXXXXX {data.token}</Col>
             <Col xs="2" className={styles.action}>
               <a href="/pool" className={styles.mt30}>
                 <button className="miniBtn button">Claim Tokens</button>
               </a>
             </Col>
          </Row>
          
        </div>
      </Col>
    </Row>
  </Container>
)};

export default Allocation;
