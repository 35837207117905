import React, { useState } from "react";
import { Col, Collapse, Container, Row } from "reactstrap";

import styles from "./faq.module.scss";

const Questions = [
  {
    id: 1,
    question: "What is Astronaut?",
    answer:
      "Astronaut is a protocol built for decentralizing the way in which new projects and ideas coming to the FANTOM  ecosystem raise capital. With Astronaut, decentralized projects will be able to raise and exchange capital cheap and fast. Users will be able to participate in a secure and interoperable environment on the FANTOM Chain.",
  },
  {
    id: 2,
    question: "What are the benefits of holding fNAUT?",
    answer:
      "fNAUT holders get special early access to new projects coming to Fantom. This gives investors an advantage in buying at private or presale price of a project.",
  },
  {
    id: 3,
    question: "When is the full platform going live?",
    answer:
      "End of october we will have our first IDO launch on our dApp. Stay tuned.",
  },
  {
    id: 4,
    question: "Why Fantom Chain?",
    answer:
      "With the current growth of the Fantom ecosystem, we have promising new projects, developments, and partnerships underway. We strive to become the leaders of the launchpad space. Fantom has faster transaction time and lower gas fees for efficiency without excessive transaction cost. ",
  },
];

const Question = ({ question, children, onClick, isOpen }) => {
  return (
    <div className={`${styles.question} ${isOpen && styles.open}`}>
      <h4 onClick={() => onClick(question)}>{question}</h4>
      <Collapse isOpen={isOpen}>
        <p>{children}</p>
      </Collapse>
    </div>
  );
};

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(false);

  const handleSelection = (question) => {
    if (selectedQuestion !== question) {
      setSelectedQuestion(question);
    } else {
      setSelectedQuestion(null);
    }
  };

  return (
    <div className={styles.faq} id="faq">
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={12} className={styles.wrapper}>
              <h3>FAQ</h3>
              {Questions.map((data) => (
                <Question
                  key={data.id}
                  question={data.question}
                  onClick={handleSelection}
                  isOpen={data.question === selectedQuestion}
                >
                  {data.answer}
                </Question>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default FAQ;
