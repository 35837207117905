import React, { Component } from "react";
import styles from './withdrawCard.module.scss';
import { Row, Col } from "reactstrap";

export default class WithdrawCard extends Component {

  constructor(props) {
      super(props);
      this.state = {
          amount:0,
          walletNaut:0,
          nautPrice:0
      }
  }

  componentDidMount(){
    this.setState({amount:'', walletNaut:20, nautPrice:1.61});
  }

  setMaxNaut(){
    this.setState({amount:this.state.walletNaut*(this.state.nautPrice)});
  }

  render() {
    return (
  <div className={styles.wrapper}>
    <div className={styles.card}>
      <input type="text" className="primary" placeholder="Enter Amount" onChange={(e)=>this.setState({amount:e.target.value})} value={this.state.amount}/>
      {/* <button className={styles.max} onClick={()=>this.setMaxNaut()}>
          <span className={styles.maxContent}>Max</span>
      </button> */}
      <Row>
        <Col xs className="d-flex align-items-center">
          <span className={styles.fee}>2.5% Fee</span>
        </Col>
        <Col xs="auto">
          <button className="button">Approve</button>
        </Col>
      </Row>
    </div>
  </div>
)}
}