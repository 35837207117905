import React from "react";
import { Col, Row, Container } from "reactstrap";

import styles from "./features.module.scss";
import rocket from "../../images/icons/rocket.png";
import helmet from "../../images/icons/helmet.png";
import safe from "../../images/icons/safe.png";

const SampleData = [
  {
    imageUrl: rocket,
    imageAlt: "Sample Image ALT",
    title: "FAIR AND RUG-PROOF LAUNCHPAD",
    description:
      "Get early access to private and pre-sales for safe high-quality projects on Fantom, without the need for large sums of capital.",
  },
  {
    imageUrl: safe,
    imageAlt: "Sample Image ALT",
    title: "LOCKED LIQUIDITY",
    description:
      "Holders of the native fNAUT token get access to IDO pools and the opportunity to get into new projects fairly without bots or whales eating the allocation.",
  },
  {
    imageUrl: helmet,
    imageAlt: "Sample Image ALT",
    title: "TIERED LAUNCH",
    description:
      "Holders of the native fNAUT token get access to IDO pools and the opportunity to get into new projects fairly without bots or whales eating the allocation.",
  },
];

const Feature = ({ imageUrl, imageAlt, title, description }) => (
  <Col lg md={6} xs={12} className={styles.feature}>
    <div className={styles.frame}>
      <img className={styles.icon} src={imageUrl} alt={imageAlt} width="100" />
    </div>
    <div>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  </Col>
);

export const FeatureList = ({features}) => (
  <>
    {features.map((data) => (
      <Feature key={Math.random()} {...data} />
    ))}
  </>
);

const Features = () => (
  <div className={styles.features} id="features">
    <section>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center section-title">MAIN FEATURES</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <FeatureList features={SampleData}/>
        </Row>
      </Container>
    </section>
  </div>
);

export default Features;
