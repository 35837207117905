import React, { useState } from "react";
import { Row, Col, Container,  Modal, ModalBody } from "reactstrap";
import Navigation from "../../components/navigation/wallerConnectNav";
import styles from "./walletConnect.module.scss";
import logo from "../../images/icons/coin.svg";
import logo1 from "../../images/logotype.png"
import logo2 from "../../images/icons/tap_unlocked.svg";
import logo3 from "../../images/bca_logo.png"
import logo4 from "../../images/zen_logo.png"
import twitter from "../../images/icons/twitter.png";
import telegram from "../../images/icons/telegram.png";
import discord from "../../images/icons/discord.png";
import unknown from "../../images/icons/unknown.png";
import binance from "../../images/icons/binance.png";
import globe from "../../images/icons/globe.svg";

// import Allocation from "../allocationPage/allocationPage";
import Schedule from "../schedulePage/shedulePage";
import ProjectDetail from "../ProjectDetail/ProjectDetail";

const Pages = {
//   Allocation: <Allocation />,
  Schedule: <Schedule />,
  // Pools: <PoolsPage />,
  Project_Detail : <ProjectDetail />
};


const WalletConnect = () => {
// const [isOpen, setOpen]= useState(false);
const data = JSON.parse(sessionStorage.getItem('pool'));
const [selectedPage, setSelectedPage] = useState("Project_Detail");
// function submitApprove(){
//   setOpen(false);
// }
return (
  <div className={styles.body}>
    <section className={styles.section1}>
        <Container>
        <Row>
      <Col lg="6" md="12" xs="12" className={styles.col}>
        <Row className={styles.sec1_row}>
        <Col lg="3" md="12" xs="12" className={styles.logo}>
          <a href={data.web}>
            {data.id==1 &&
              <img className="w-100" src={logo1} alt="Astronaut" />
            }
            {data.id==2 &&
              <img className="w-100" src={logo2} alt="Astronaut" />
            }
            {data.id==3 &&
              <img className="w-100" src={logo3} alt="Astronaut" />
            }
            {data.id==4 &&
              <img className="w-100" src={logo4} alt="Astronaut" />
            }
               {data.id==5 &&
              <img className="w-100" src={logo4} alt="Astronaut" />
            }
          </a>
        </Col>
        <Col lg="9" md="12" xs="12" className={styles.col}>
          <div className={styles.social}>
            <h4>{data.name}</h4>
            <div className={styles.buttons}>
              <a target="_blank" href={data.twitter} rel="noreferrer">
                <button className="circle button">
                  <img src={twitter} alt="twitter" />
                </button>
              </a>

              <a target="_blank" href={data.tele} rel="noreferrer">
                <button className="circle button">
                  <img src={telegram} alt="telegram" />
                </button>
              </a>

              <a target="_blank" href={data.discord} rel="noreferrer">
                <button className="circle button">
                  <img src={discord} alt="discord" />
                </button>
              </a>

              <a target="_blank" href="https://etherscan.io/token/0x9f599410d207f3d2828a8712e5e543ac2e040382" rel="noreferrer">
                <button className="circle button">
                  <img src={binance} alt="binance" />
                </button>
              </a>

              <a target="_blank" href={data.web} rel="noreferrer">
                <button className="circle button">
                  <img src={globe} alt="Tapcoin" />
                </button>
              </a>
            </div>
            </div>
          </Col>
          </Row>
        <div className={styles.sec1_row2}>
          <div className={styles.row2_item1}>
            <h4 className="section-title-no-glow text-center">BUSD</h4>
          </div>
          <div className={styles.row2_item2}>
          <div className={styles.badgePink}>Open</div>
          </div>
        </div>
      </Col>
     
      {/* <div className={styles.half}> */}
        <Col lg="6" md="12" xs="12">
          <div className={styles.inSec}>
        <p className="section-title-no-glow">{data.desc}</p>
        <a href="/poolDetail" className={styles.mt30}>
          <button className="button">Connect Wallet</button>
        </a>
        </div>
        </Col>
      {/* </div> */}
      </Row>
      </Container>
    </section>
    
    <section className={styles.section2}>
        <Container>
      <Row>
        <Col md="4" xs={12} className={styles.colsect2}>
          <div  className="d-flex justify-content-center flex-column">
          <div className={styles.mb30}>
            <h4 className={styles.title}>First Come First Serve opens in</h4>
          </div>
          <br></br>
          {/* <div className={styles.row2_item1}>
            <a className={styles.buttonSpace}>
              <button className="button" onClick={() => setOpen(true)}>Approve</button>
            </a>
            <a href="/pool" className={styles.buttonSpace}>
              <button className="button">Join pool</button>
            </a>
          </div> */}
          </div>
        </Col>
        <Col md={6} xs="12" className={styles.colsect2_1}>
          <div className={styles.stats}>
            <Row className="mb-2">
              <Col xs="8">
                {/* <small>Balance</small> */}
                <p className={styles.value}>0d 3h 50m 45s</p>
                <p className={styles.value1}>Allocation round</p>
              </Col>
              <Col xs="4">
                {/* <small>Your Tier</small> */}
                <p className={styles.value}>2937</p>
                <p className={styles.value1}>Participants</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col  md={2} xs="12">
        </Col>
      </Row>
      </Container>
    </section>
   
    <section className={styles.section3}>
      <div className={styles.details}>
        <section>
          <Container>
            <>
              <Navigation
                handleOnClick={name => setSelectedPage(name)}
                activePage={selectedPage}
              />
            </>
          </Container>
          <div className={styles.panel}>{selectedPage && Pages[selectedPage]}</div>
        </section>
      </div>
    </section>
  </div>
);
}
export default WalletConnect;
