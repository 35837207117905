import React from "react";
import { Col, Container, Row } from "reactstrap";

import jetswap from "../../images/icons/market.png";
import quick from "../../images/icons/quick.png";
import bakeryswap from "../../images/icons/bakeryswap.png";
import apeswap from "../../images/icons/apeswap.png";
import styles from "./marketMakers.module.scss";
import spookyswap from "../../images/icons/spookyswap.png";
import darknight from "../../images/icons/darknight.png";

const SampleData = [
  {
    iconUrl: darknight,
    name: "Dark knight",
    link:"https://dark.knightswap.financial/images/knight/k4.svg"
  },
  {
    iconUrl: spookyswap,
    name: "Spookyswap",
    link:"https://pbs.twimg.com/profile_images/1417105787214831616/0lc-2v1A_400x400.png"
  }
 
];

const MarketMarkers = () => (
  <div className={styles.market} id="market">
    <section>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center section-title">
            AUTOMATED MARKET MAKERS <br></br> INTEGRATED
            </h2>
            <Row className="justify-content-center">
              {SampleData.map(data => (
                <Col className={styles.maker} lg={3} md={6} xs={10} key={Math.random()}>
                  <a href={data.link} target="_blank">
                    <img alt={data.name} src={data.iconUrl} width="20" />
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default MarketMarkers;
