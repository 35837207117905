import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "reactstrap";
import Navigation from "../../components/transactionNav/navigation";
import DepositPage from "../depositPage/depositPage";
import WithdrawPage from "../withdrawPage/withdrawPage";
import styles from "./transaction.module.scss";
import {useParams } from 'react-router-dom';

import {useWallet} from '@binance-chain/bsc-use-wallet'

import NAUT_ABI from "../../BlockchainProvider/ABI/NAUT_ABI.json"
import config from "../../BlockchainProvider/config"
import STAKING_ABI from  "../../BlockchainProvider/ABI/NEWSTAKING.json"
import Web3 from "web3"


const TransactionPage = (props) => {
  const { contract } = useParams();

  const [selectedPage, setSelectedPage] = useState("Deposit");
  const [isICOOver, setIsICOOver] = useState(true);
  const [expireTime, setExpireTime] = useState()
  const [nautContract, setNautContact] = useState(null)
  const [stakingContract, setStakingContact] = useState()
  const [web3, setWeb3] = useState()
  const [walletAddress, setWalletAddress] = useState("");

  const [tickerText, setTickerText] = useState();
  const [tickerTimer, setTickerTimer] = useState();



  const [tier1Amount,setTier1Amount] = useState();
  const [tier2Amount,setTier2Amount] = useState();
  const [tier3Amount,setTier3Amount] = useState();
  const [tier4Amount,setTier4Amount] = useState();



  const wallet = useWallet();

  const initContracts = () => {
    let _web3 = new Web3(window.ethereum);
    const _nautContract = new _web3.eth.Contract(NAUT_ABI, config.NAUT_CONTRACT)
    const _stakingContract = new _web3.eth.Contract(STAKING_ABI, contract);

    setWeb3(_web3)
    setNautContact(_nautContract);
    setStakingContact(_stakingContract)


  }

  useEffect(() => {
    if (wallet.status === 'connected') {
      setWalletAddress(wallet.account)
      initContracts()
    }

  }, [wallet])



  // const Pages = {
  //   Deposite: depositPage,
  //   Withdraw: <WithdrawPage />,
  // };



  const renderPages = () => {

    if (selectedPage === "Deposit") {

      return <DepositPage
        onExpireTime={(time) => {
          setExpireTime(time)
        }}

        onTickerUpdate={(tickerText, time) => {
          // setTickerStatus()
          setTickerText(tickerText)

          setTickerTimer(time)
        }}


        onTierAmountLoaded={(data)=>{
          setTier1Amount(data[0])
          setTier2Amount(data[1])
          setTier3Amount(data[2])
          setTier4Amount(data[3])

        }}


        onIcoOver={(bool) => {
          console.log("onIcoOver",bool)
          setIsICOOver(bool)
        }}
        account={walletAddress && walletAddress}
        stakingContract={stakingContract && stakingContract}
        stakingAddress={contract && contract}
        nautContract={nautContract && nautContract}
      />
    } 
    return <WithdrawPage
      isICOOver={isICOOver}
      account={walletAddress && walletAddress}
      stakingContract={stakingContract && stakingContract}
      stakingAddress={contract && contract}
      nautContract={nautContract && nautContract} />
  }


  return (
    <div className={styles.details}>


      <section>
        <Container>
          <>
            <Navigation
              tickerText={tickerText}
              tickerTimer={tickerTimer}
              handleOnClick={name => setSelectedPage(name)}
              activePage={selectedPage}
            />
          </>
        </Container>




        <div className={styles.panel}>


          {selectedPage ? renderPages() : null}



          <div style={{marginLeft:"10%", marginRight:"10%"}}>
          <h4 className={styles.hdr}>Tiers</h4>
      <Row className={styles.card2}>
        <Col xs="3" className={styles.col_moon}>
          <h5>MOON</h5>
          <span >Requirements</span>
          <p>{tier1Amount}</p>
          <span >Pool Weight</span>
          <p >20</p>
        </Col>
        <Col xs="3" className={styles.col_mar}>
          <h5>MARS</h5>
          <span >Requirements</span>
          <p>{tier2Amount}</p>
          <span >Pool Weight</span>
          <p >66</p>
        </Col>
        <Col xs="3" className={styles.col_star}>
          <h5>STAR</h5>
          <span >Requirements</span>
          <p>{tier3Amount}</p>
          <span >Pool Weight</span>
          <p>145</p>
        </Col>
        <Col xs="3" className={styles.col_galaxy}>
          <h5>GALAXY</h5>
          <span >Requirements</span>
          <p>{tier4Amount}</p>
          <span >Pool Weight</span>
          <p >400</p>
        </Col>
      </Row>
   
   
          </div>


        </div>




      </section>
    </div>
  );
};

export default TransactionPage;
