import React, { useState,useEffect } from "react";
import { Row, Container } from "reactstrap";
import {useWallet} from '@binance-chain/bsc-use-wallet'


import Navigation from "../../components/depositeNavigation/navigation";
import DepositeScreen from "../depositeScreen/depositeScreen";
import WithdrawScreen from "../WithdrawScreen/WithdrawScreen";
import styles from "./depositeWithdraw.module.scss";
import config, { BUSD_CONTRACT } from "../../BlockchainProvider/config";
import FSFC_ABI from "../../BlockchainProvider/ABI/FSFC_ABI_NEW.json"
import BUSD_ABI from "../../BlockchainProvider/ABI/NAUT_ABI.json"

import Web3 from "web3"




const DepositeWithdrawPage = () => {
  const [remainingTime, setRemainingTime] = useState();

  const [selectedPage, setSelectedPage] = useState("Deposit");
  const [ web3, setWeb3] = useState()
  const [ account, setAccount] = useState()
  const [fcfsContract,setFcfsContract] = useState();
  const [busdContract,setBUSDContract] = useState();
  const [claimEnabled,setClaimEnabled] = useState();

  const wallet = useWallet();

  const initContracts = async() => {
    let _web3 = new Web3(window.ethereum);
    const _fcfsContract = new _web3.eth.Contract(FSFC_ABI, config.FCFS_CONTRACT_NEW)
    const _busdContract = new _web3.eth.Contract(BUSD_ABI, config.BUSD_CONTRACT)


    let _claimEnabled  = await _fcfsContract.methods.claimenabled().call();
    setClaimEnabled(_claimEnabled)
    setWeb3(_web3)

    setFcfsContract(_fcfsContract);
    setBUSDContract(_busdContract)
  }

  useEffect(() => {
    if (wallet.status === 'connected') {
      setAccount(wallet.account)
      initContracts()
    }

  }, [wallet])

  






  const initTimer=(deadline)=>{
    var interval = setInterval(function () {
      var now = new Date().getTime();
      var t = deadline - now;
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((t % (1000 * 60)) / 1000);
      setRemainingTime("Starting in "+days + "d "
      + hours + "h " + minutes + "m " + seconds + "s ");
      if (t < 0) {
        clearInterval(interval);
        setRemainingTime("Started")

      }
    }, 1000);
  }


  return (
    <div className={styles.details}>
      <section className={styles.pb}>
       <Container>
       <Row style={{}}>
          <div style={{marginLeft: 10}}>
            <h5>FCFS Round: First come first serve</h5>
            <span>Final round if IAO’s are not filled</span>

          </div>

          <div>
          {remainingTime?  <button
          style={{marginLeft:40,
          width:"100%"}}
          class={styles.timer} >
           <span style={{fontSize:25,fontWeight:"bold",marginLeft:10,marginRight:10}}>
           {remainingTime}
           </span>
          </button>:null}
            
          </div>
    
        </Row>
       </Container>
        {/* <div >{selectedPage && Pages[selectedPage]}</div> */}
        {/* {renderPages()} */}


       <DepositeScreen
        fcfsContract={fcfsContract}
        busdContract={busdContract}
        account={account}
        claimEnabled = {claimEnabled}
        onTimeRecevied={(t)=>{

          console.log("tiime",claimEnabled)

          if(claimEnabled ==true){
            setRemainingTime("Claim Tokens")
          }else if(t === "Finished"){
            setRemainingTime("Finished")

          }else{
            initTimer(t)

          }
        }}
      
    //  onExpireTime = {(time)=>{
    //   setExpireTime(time)
    //  }}
  
    //  onIcoOver={(bool)=>{
    //    setIsICOOver(bool)
    //  }}
    //   account={walletAddress && walletAddress}
    //   stakingContract={stakingContract && stakingContract}
    //   nautContract={nautContract && nautContract}
    /> 
      </section>
    </div>
  );
};


export default DepositeWithdrawPage;
