import React from "react";
import { Col, Row, Container } from "reactstrap";

import TierCard from "../../components/tierCard/tierCard";
import { SampleTierData } from "../../components/tiers/tiers";
import styles from "./tiersPage.module.scss";

const tierData = {
  id: 1,
  title: "Earth",
  requirements: "500+",
  weight: 1,
};

const TiersPage = () => (
  <section className={styles.section1}>
  <Container>
    <Row className="justify-content-center">
      <Col xs="12" md="9">
        <h2 className="section-title-no-glow text-center">Tiers</h2>
        <p className="text-center">
          Astronaut has a unique tier system which requires users to hold a
          certain amount of NAUT tokens to participate in it’s launchpad. It’s a
          pool weight system based on base allocations. The more NAUT you stake,
          the larger allocations you receive by pool weight.
        </p>
      </Col>
    </Row>
    {/* <Row className="d-flex justify-content-center">
      {SampleTierData.map((data) => (
        <Col lg="4" md="6" xs="12">
          <TierCard
            key={data.id}
            title={data.title}
            requirements={data.requirements}
            weight={data.weight}
          />
        </Col>
      ))}
    </Row> */}
  </Container>
  <Container>
  <Row className={styles.slider}>
    {SampleTierData.map(data => <TierCard key={data.id} title={data.title} requirements={data.requirements} weight={data.weight} />)}
  </Row>
</Container>
</section>
);

export default TiersPage;
