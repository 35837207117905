import React from "react";
import { Col, Row, Container } from "reactstrap";

import astroLogo from "../../images/locker-logo-naut.png"
import lockerLogo from "../../images/locker-logo.png";
import styles from './lockerPage.module.scss';

const SampleData = [
  {
    id: 1,
    name: "DailyS",
    value: "$843,171",
    tokens: "99,500,000 (0.00%)",
    price: "$0.01",
    nextUnlock: "5 months left"
  },
  {
    id: 1,
    name: "DailyS",
    value: "$843,171",
    tokens: "99,500,000 (0.00%)",
    price: "$0.01",
    nextUnlock: "5 months left"
  },
  {
    id: 1,
    name: "DailyS",
    value: "$843,171",
    tokens: "99,500,000 (0.00%)",
    price: "$0.01",
    nextUnlock: "5 months left"
  },
  {
    id: 1,
    name: "DailyS",
    value: "$843,171",
    tokens: "99,500,000 (0.00%)",
    price: "$0.01",
    nextUnlock: "5 months left"
  },
  {
    id: 1,
    name: "DailyS",
    value: "$843,171",
    tokens: "99,500,000 (0.00%)",
    price: "$0.01",
    nextUnlock: "5 months left"
  },
  {
    id: 1,
    name: "DailyS",
    value: "$843,171",
    tokens: "99,500,000 (0.00%)",
    price: "$0.01",
    nextUnlock: "5 months left"
  },
];

const LockerPage = () => (
  <>
    <div className={styles.locker}>
      <section>
        <Container>
          <Row>
            <div className={styles.logos}>
              <div><img src={astroLogo} alt="Astronaut" /></div>
              <div className={styles.mar}><img src={lockerLogo} alt="Locker" /></div> 
            </div>
            <Col xs="12" className={styles.text}>
              <small>$</small>
              <span>2,083,318 locked in 21 projects</span>
            </Col>
          </Row>
          <Row className="justify-content-center d-flex">
            <Col md="8" className={styles.row}>
              <input type="text" placeholder="Address" />
              <button className="button">Lock Tokens</button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
    <div className={styles.table}>
      <section className={styles.sec1}>
        <Container>
          <Col xs="12" className={styles.header}>
            <Row>
              <Col xs="2">Name</Col>
              <Col xs="2">Total value Locked</Col>
              <Col xs="2">Tokens Locked</Col>
              <Col xs="2">Token Price</Col>
              <Col xs="4">Next Unlock</Col>
            </Row>
          </Col>
          {SampleData.map(data => (
            <Col xs="12" className={styles.tr}>
              <Row className="d-flex align-items-center" key={Math.random()}>
                <Col xs="2">{data.name}</Col>
                <Col xs="2">{data.value}</Col>
                <Col xs="2">{data.tokens}</Col>
                <Col xs="2">{data.price}</Col>
                <Col xs="2">{data.nextUnlock}</Col>
                <Col xs="2" className="text-right pr-0">
                  <button className="button small m-2">View</button>
                </Col>
              </Row>
            </Col>
          ))}
        </Container>
      </section>
    </div>
  </>
);

export default LockerPage;
