import React from "react";
import styles from "./navigation.module.scss";
import safe from "../../images/icons/safe.png";

const NavigationButton = ({ onClick, iconUrl, name, isActive }) => (
  <button
    onClick={() => onClick(name)}
    className={isActive && styles.active}
  >
    {iconUrl && <img src={iconUrl} width="20" alt="icon" /> }
    <span>{name.replace("_"," ")}</span>
  </button>
);

const Navigation = ({ handleOnClick, activePage }) => {
  return (
    <div className={styles.navigation}>
      <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="Project_Detail"
        isActive={activePage === "Project_Detail"}
      />
      <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="Schedule"
        isActive={activePage === "Schedule"}
      />
      <NavigationButton
        onClick={handleOnClick}
        // iconUrl={safe}
        name="Allocation"
        isActive={activePage === "Allocation"}
      />
    </div>
  );
};

export default Navigation;
