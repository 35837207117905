import React, { useState,useEffect } from "react";
import { Col, Row } from "reactstrap";
import styles from './poolpage.module.scss';
import PoolCard from "../../components/poolCard/poolCard"; 
import StakeCard from "../../components/stakeCard/stakeCard";


const StakePage = () => {

  const [] = useState();

  const SampleData = [

    
    {
      id: 2,
      description:
        "Ninja’s World is a blockchain-based Ninja adventure game. Players are able to adventure into the Ninja’s World to engage in battles, daily tasks and upgrade the rank in the arena to earn abundant token rewards.",
      status: "Open",
      currencyRateType:"$NJW/USDC",
      currencyRate: "1USDC= 33.33 $NJW",
      cap: "150k",
      acess: "Public",
      participants: "3075",
      percentage: "100%",
      completion: "2,000,000",
      website:"https://ninjasworld.me/",
      twitterLink:"https://twitter.com/NinjaInMeta",
      telegramLink:"https://t.me/NinjasWorldOfficial",
      discordLink:"#",
      contract: "0x7F0B42D3DB9CaD8E8a7fF91097CA5C3772A1536F",
      closed:false

    },
    {
      id: 1,
      description:
        "FABWELT creates a gaming platform that brings blockchain technology into the core of high quality games of all types or genres. We achieve this by utilizing the finest tech of both worlds, beautiful high-quality games and the latest blockchain technology.",
      status: "Close",
      currencyRateType:"pNAUT/USDC",
      currencyRate: "1USDC= 71.42 WELT",
      cap: "100k",
      acess: "Public",
      participants: "3075",
      percentage: "100%",
      completion: "2,000,000",
      website:"https://www.fabwelt.com/",
      twitterLink:"https://twitter.com/FabweltToken",
      telegramLink:"https://t.me/FabweltToken",
      discordLink:"https://discord.gg/5DyazH7vPj",
      contract: "0xec85FfdC6C6646a8c3aa032b0A319656e0de19BE",
      closed:true

    },
    // {
    //   id: 2,
    //   description:
    //     "Tapcoins (‘TTT’) are digital tokens built on the Ethereum Blockchain. They are the foundation for blockchain gaming on the Tap Platform. Tapcoins allow gamers to transfer their in-game currency from one game to the next – making it possible to get “paid to play!”",
    //   status: "Open in TBA",
    //   currencyRateType:"USDC/TTT",
    //   currencyRate: "TBD",
    //   cap: "TBD",
    //   acess: "Private",
    //   participants: "xxxx",
    //   percentage: "100%",
    //   completion: "xxxxx/xxxxx",
    //   website:"https://www.tapproject.net/",
    //   twitterLink:"https://twitter.com/Tap_Coin",
    //   telegramLink:"https://t.me/tapcoin",
    //   discordLink:"https://discord.com/invite/5DyazH7vPj"
    // },
  ];

  return(
    <div>
      <Row>
        <Col xs="12">
          <h2 className="section-title-no-glow text-center">STAKING</h2>
        </Col>
      </Row>
      <Row>
        <div className="row p-5">
        {SampleData.map(data => <StakeCard 

                                  key={data.id} 
                                  {...data} />)}
                                  </div>
      </Row>
    </div>
  );
}

export default StakePage;
